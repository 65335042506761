export const manualPspList = [{
    alias: "bitcoin_manual",
    symbol: "BTC"
},{
    alias: "tron_manual",
    symbol: "TRX"
},{
    alias: "ethereum_manual",
    symbol: "ETH"
},{
    alias: "usdt_sol_manual",
    symbol: "USDT"
},{
    alias: "usdt_erc20_manual",
    symbol: "USDTE",
},{
    alias: "usdt_trc20_manual",
    symbol: "USDTT",
}]
